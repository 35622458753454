/**
 *
 * GryphonSpinner
 *
 */

.ui-spinner{
  position: relative;

  &.ui-widget-content {    //<<<<<<<<<<<<<<!!!
    border: 0;
  }

  .ui-spinner-input {
    margin: 0;
  }


  input[type=number]::-webkit-inner-spin-button{
    display: none;
  }

  .ui-spinner-button {
    position: absolute;   //<<<<<<<<<<<<<<<!!!
    top: 1px;       //<<<<<<<<<<<<<<<!!!
    border-top: none;
    border-bottom: none;
    height: 16px;     //<<<<<<<<<<<<<<<!!!
    width: 16px;   //<<<<<<<<<<<<<<<!!!
    box-sizing: initial;   //<<<<<<<<<<<<<<<!!!
    padding: 4px;
    border-color: $inputBorder;
    @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px) {
      padding: 4px 4px 3px 4px;
    }
    &+.btn {    //<<<<<<<<<<<<<<<!!!
      margin: 0;
    }

    &:active, &.selected, &.active{
      @include box-shadow(#{inset 0 1px 3px rgba(0, 0, 0, 0.25)});

      &[disabled]{
        @include box-shadow(#{none});
      }

      border-bottom: 0;
      border-right: 0;
      border-top: 0;
    }
    border-right: 0;
  }
  .ui-spinner-down{
    right: 1px;
  }

  .ui-spinner-up{
    position: absolute;
    right: 26px;
    top: 1px;
  }
}
