.tag {
  width: auto;
  max-width: calc(100% - 22px);
  float: left;
  padding: 0 10px;
  margin: 2px 10px 4px 0;
  position: relative;
  overflow: hidden;
  line-height: 22px;
  font-size: 12px;
  color: $black;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  border: 1px solid $tag-border-color;
  background: $tag-bg-color;
  min-width: 10px;

  &.clickable {
    &:not(.state-disabled) {
      color: $tag-font-color;
      cursor: pointer;

      &.hovered,
      &:hover,
      &:focus {
        outline: 0;
        background: $tag-bg-color-hover;
        border: solid 1px $tag-border-color-hover;
        color: $tag-font-color-hover;
      }

      &.active,
      &.selected,
      &:active {
        background: $tag-bg-color-active;
        border: solid 1px $tag-border-color-active;
        color: $tag-font-color-active;
      }
    }

    &.removable {
      padding-right: 44px;
      max-width: 100%;

      .tag-close {
        width: 44px;
      }
    }
  }

  &.removable {
    padding-right: 34px;
    max-width: 100%;

    .tag-body {
      //max-width: 100%;
    }

    .tag-close {
      position: absolute;
      right: 0;
      top: 0;
      width: 34px;
      height: 22px;
      display: inline-block;
      float: right;
      cursor: pointer;

      &:hover {
        .tag-close-icon {
          opacity: 1;
        }
      }

      .tag-close-icon {
        display: inline-block;
        float: right;
        width: 10px;
        height: 10px;
        background: url(tag-close-icon.svg);
        margin-right: 6px;
        margin-top: 6px;
        opacity: 0.65;
      }
    }

    &.state-disabled {
      .tag-close {
        cursor: default;

        .tag-close-icon,
        .tag-close-icon:hover {
          opacity: 0.35;
        }
      }
    }
  }

  .tag-body {
    float: left;
    max-width: 100%;
    @include text-overflow();
  }

  &.statable {
    &:active {
      .state-marker {
        opacity: 0.65;
      }
    }

    &.state-included {
      .state-marker {
        background: $tagMarkerBackground;
      }
    }

    &.state-excluded {
      .state-marker {
        background: #cc2930;
      }
    }

    .state-marker {
      width: 6px;
      height: 22px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
    }
  }

  &.state-disabled {
    color: $tag-font-color-disabled;
    background: $tag-bg-color-disabled;
    border: 1px solid $tag-border-color-disabled;

    .state-marker {
      opacity: .35;
    }
  }
}