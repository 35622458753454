.ui-autocomplete.ui-menu.ui-multiplelist {
  max-height: 301px;
  padding: 0 2px 0 1px;
  overflow: hidden;

  .containerItem {
    max-height: 270px;
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      margin: 0;
      padding-left: 14px;
      list-style: none;

      >li {
        padding-top: 4px;

        label.checkbox {
          height: 21px;
        }
      }
    }
    li {
      label label {
        float: left;
      }
      a {
        display: inline-block;
        width: calc(100% - 16px);
        padding-left: 4px;

        &:hover {
          background: none;
        }
        &:active {
          color: #000 !important;
          background-color: #fff;
          text-shadow: none;
        }
      }
    }
  }
  .controlsContainer {
    width: calc(100% + 1px);
    height: 28px;
    margin: 0 0 1px 0;
    font-size: 13px;
    color: $newBlueDark;
    @include text-shadow(-1px 1px #fff);
    border-top: 1px solid #babec8;
    @include box-shadow(0 1px 0 #fff inset);
    background: #f6f6f6;
    .mainCheckbox {
      width: 46px;
      display: inline-block;
      padding-left: 32px;
      vertical-align: top;
      margin-top: 1px;
      padding-top: 2px;
      padding-bottom: 3px;
      border-right: 1px solid #babec8;
    }
    .applyItem {
      width: calc(100% - 46px);
      border-left: 1px solid #fff;
      display: inline-block;
      line-height: 26px;
      padding-bottom: 1px;
      text-indent: 14px;
      padding-right: 15px;
      &:hover:not(.disabled) {
        background: #dedede;
        @include box-shadow(inset 0 1px #fff);
        cursor: pointer;
      }
      &:active:not(.disabled) {
        background: #dedede;
        @include box-shadow(inset 0 1px #ccc);
      }
      &.disabled {
        color: #999;
        background: #f6f6f6;
        @include box-shadow(inset 0 1px #fff);
      }
    }
  }

}