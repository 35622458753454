::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	margin:0 5px;

}

/* Track */
::-webkit-scrollbar-track {
	background-color: #F0F3FA;
	border-radius: 10px;
	padding: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba($newBlueDark, .4);

	&:hover {
		background: #B3CCFA;  
	}

	&:active {
		background: $newBlueDark;
	}
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba($newBlueDark, .4);
}