//
// Forms
// --------------------------------------------------

@import "variables_extended";

// GENERAL STYLES
// --------------

form {
  margin: 0 0 $baseLineHeight;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $baseLineHeight * .25;
  font-size: $baseFontSize * 1.5;
  font-weight: 400;
  line-height: $baseLineHeight * 1.5;
  color: $grayDark;
  border: 0;
  border-bottom: 1px solid #e5e5e5;

  small {
    font-size: $baseLineHeight * .75;
    color: $grayLight;
  }
}

label,
input,
button,
select,
textarea {
  @include font-shorthand($baseFontSize,normal,$baseLineHeight);
}
input,
button,
select,
textarea {
  font-family: $baseFontFamily;
}

label {
  display: block;
  margin-bottom: 4px;
}

// Form controls
// -------------------------

// Shared size and type resets
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  height: 26px;
  padding: 2px 6px;
  margin-bottom: 9px;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #000;
  @include border-radius($inputBorderRadius);
}

input,
textarea,
.uneditable-input {
  width: 206px;
}

textarea {
  height: auto;
  resize: none;
}

textarea,
select,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.ui-autocomplete,
.uneditable-input {
  background-color: $inputBackground;
  border: 1px solid $inputBorder;
  border-radius: $inputBorderRadius;
  -webkit-border-radius: $inputBorderRadius;
  @include transition(#{border linear .2s, box-shadow linear .2s});

  &:focus {
    border-color: $selectHover;
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    @include box-shadow(#{0 0 4px $selectHover});
  }
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  line-height: normal;
  cursor: pointer;
}

// Reset width of input images, buttons, radios, checkboxes
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}

textarea,
input[type="file"],
input[type="search"],
input[type="password"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="tel"],
input[type="url"],
input[type="email"] {
  -webkit-appearance: none !important;
  -webkit-border-radius: 0 !important;
}

select {
  -webkit-border-radius: 0 !important;
}

select,
input[type="file"] {
  line-height: 30px;
}
//Experimental
select {
  padding: 2px 2px 2px 6px;
  box-sizing: border-box;
  line-height: 26px;
  height: 26px;
}

// Make select elements obey height by applying a border
select {
  width: 220px;
  border: 1px solid $inputBorder;
  background-color: $inputBackground;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
}

// Focus for select, file, radio, and checkbox
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  @include tab-focus();
}

select {
  option {
    &:hover {
      background-color: $selectHover;
    }
  }
}

select:active, select:hover {
  outline: none
}


// Uneditable inputs
// -------------------------

// Make uneditable inputs look inactive
.uneditable-textarea {
  color: $grayLight;
  background-color: darken($inputBackground, 1%);
  border-color: $inputBorder;
  @include box-shadow(inset 0 1px 2px rgba(0,0,0,.025));
  cursor: not-allowed;
}
.uneditable-input {
  color: $grayLight;
  border: 0;
  @include box-shadow(0 0);
  padding-top: 3px;
}

// For text that needs to appear as an input but should not be an input
.uneditable-input {
  overflow: hidden; // prevent text from wrapping, but still cut it off like an input does
  white-space: nowrap;
}

// Make uneditable textareas behave like a textarea
.uneditable-textarea {
  width: auto;
  height: auto;
}


// Placeholder
// -------------------------

// Placeholder text gets special styles because when browsers invalidate entire lines if it doesn’t understand a selector
input,
textarea {
  @include placeholder(#808080);
}


// CHECKBOXES & RADIOS
// -------------------

// Indent the labels to position radios/checkboxes as hanging
.radio,
.checkbox {
  min-height: 19px;
  padding-left: 18px;
  padding-bottom: 1px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}

.controls > .checkbox:first-child {
  padding-top: 5px; // has to be padding because margin collaspes
}

// Radios and checkboxes on same line
// TODO v3: Convert .inline to .control-inline
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px; // space out consecutive inline controls
}

// INPUT SIZES
// -----------

// General classes for quick sizes
.input-mini       { width: 55px; }
.input-small      { width: 90px; }
.input-medium     { width: 150px; }
.input-large      { width: 210px; }
.input-xlarge     { width: 270px; }
.input-xxlarge    { width: 530px; }

// DISABLED STATE
// --------------

// Disabled and read-only inputs
input[disabled],
select[disabled],
textarea[disabled] {
  cursor: not-allowed;
  background-color: $inputDisabledBackground;
  @include placeholder(#ccc);
  color: #ccc;
  border: solid 1px $inputDisabledBorderColor;
}
// Explicitly reset the colors here
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}




// FORM FIELD FEEDBACK STATES
// --------------------------

// Warning
.control-group.warning {
  @include formFieldState($warningText, $warningText, $warningBackground);
}
// Error
.control-group.error {
  @include formFieldState($errorText, $errorText, $errorBackground);
}
// Success
.control-group.success {
  @include formFieldState($successText, $successText, $successBackground);
}
// Success
.control-group.info {
  @include formFieldState($infoText, $infoText, $infoBackground);
}

// HTML5 invalid states
// Shares styles with the .control-group.error above
input:focus:required:invalid,
textarea:focus:required:invalid,
select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
  &:focus {
    border-color: darken(#ee5f5b, 10%);
    @include box-shadow(0 0 6px lighten(#ee5f5b, 20%));
  }
}



// FORM ACTIONS
// ------------

.form-actions {
  padding: $baseLineHeight;
  margin-top: $baseLineHeight;
  //margin-bottom: $baseLineHeight;
  background-color: $formActionsBackground;
  border-top: none; //1px solid #e5e5e5;
  @include clearfix(); // Adding clearfix to allow for .pull-right button containers
}



// HELP TEXT
// ---------

.help-block,
.help-inline {
  color: lighten($textColor, 15%); // lighten the text some for contrast
}

.help-block {
  display: block; // account for any element using help-block
  margin-bottom: calc($baseLineHeight / 2);
}

.help-inline {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}



// INPUT GROUPS
// ------------

// Allow us to put symbols and text within the input field for a cleaner look
.input-append,
.input-prepend {
  margin-bottom: 5px;
  white-space: nowrap; // Prevent span and input from separating

  input,
  select,
  .uneditable-input {
    position: relative; // placed here by default so that on :focus we can place the input above the .add-on for full border and box-shadow goodness
    margin-bottom: 0; // prevent bottom margin from screwing up alignment in stacked forms
    font-size: $baseFontSize;
    vertical-align: top;
    @include border-radius(0 $inputBorderRadius $inputBorderRadius 0);
    // Make input on top when focused so blue border and shadow always show
    &:focus {
      z-index: 2;
    }
  }
  .add-on {
    display: inline-block;
    width: auto;
    height: $baseLineHeight;
    min-width: 26px;
    padding: 2px 5px;
    font-size: $baseFontSize;
    font-weight: normal;
    line-height: $baseLineHeight;
    text-align: center;
    text-shadow: 0 1px 0 $white;
    background-color: $grayLighter;
    border: 1px solid #ccc;
  }
  .add-on,
  .btn {
    vertical-align: top;
    @include border-radius(0);
  }
  .active {
    background-color: lighten($green, 30);
    border-color: $green;
  }
}
.input-prepend {
  .add-on,
  .btn {
    margin-right: -1px;
  }
  .add-on:first-child,
  .btn:first-child {
    @include border-radius($inputBorderRadius 0 0 $inputBorderRadius);
  }
}
.input-append {
  input,
  select,
  .uneditable-input {
    @include border-radius($inputBorderRadius 0 0 $inputBorderRadius);
  }
  .add-on,
  .btn {
    margin-left: -1px;
  }
  .add-on:last-child,
  .btn:last-child {
    @include border-radius(0 $inputBorderRadius $inputBorderRadius 0);
  }
}
// Remove all border-radius for inputs with both prepend and append
.input-prepend.input-append {
  input,
  select,
  .uneditable-input {
    @include border-radius(0);
  }
  .add-on:first-child,
  .btn:first-child {
    margin-right: -1px;
    @include border-radius($inputBorderRadius 0 0 $inputBorderRadius);
  }
  .add-on:last-child,
  .btn:last-child {
    margin-left: -1px;
    @include border-radius(0 $inputBorderRadius $inputBorderRadius 0);
  }
}



// SEARCH FORM
// -----------

input.search-query {
  line-height: 1;
  padding-right: 7px;
  padding-right: 4px \9;
  padding-left: 7px;
  padding-left: 4px \9; /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0; // Remove the default margin on all inputs
  @include border-radius(1px);
}

/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  @include border-radius(0); // Override due to specificity
}
.form-search .input-append .search-query {
  @include border-radius(1px 0 0 1px);
}
.form-search .input-append .btn {
  @include border-radius(0 1px 1px 0);
}
.form-search .input-prepend .search-query {
  @include border-radius(0 1px 1px 0);
}
.form-search .input-prepend .btn {
  @include border-radius(1px 0 0 1px);
}

.search-wrapper {

  button[type="submit"]{
    background: url(not_active_search_icon.png);
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0;
    text-indent: -999em;
    width: 25px;
    height: 16px;
    background-position: center;
    border-left: 1px solid #BFC7CC;
    position: absolute;
    top: 5px;
    right: 1px;
  }

  input[type="search"]:focus + button[type="submit"]{
    background-image: url(search_icon.png);
  }

  input[type="search"]{
    width: 100%;
    height: $baseLineHeight + 6;
    box-sizing: border-box;
  }

  //display: inline-block;
  position: relative;

}




// HORIZONTAL & VERTICAL FORMS
// ---------------------------

// Common properties
// -----------------

.form-search,
.form-inline,
.form-horizontal {
  input,
  textarea,
  select,
  .help-inline,
  .uneditable-input,
  .input-prepend,
  .input-append {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  // Re-hide hidden elements due to specifity
  .hide {
    display: none;
  }
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
// Remove margin for input-prepend/-append
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
// Inline checkbox/radio labels (remove padding on left)
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
// Remove float and margin, set to inline-block
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}


// Margin to space out fieldsets
.control-group {
  margin-bottom: calc($baseLineHeight / 2);
}

// Legend collapses margin, so next element is responsible for spacing
legend + .control-group {
  margin-top: $baseLineHeight;
  -webkit-margin-top-collapse: separate;
}

// Horizontal-specific styles
// --------------------------

.form-horizontal {
  // Increase spacing between groups
  .control-group {
    margin-bottom: $baseLineHeight;
    @include clearfix();
  }
  // Float the labels left
  .control-label {
    float: left;
    width: $horizontalComponentOffset - 20;
    padding-top: 3px;
    text-align: right;
  }
  // Move over all input controls and content
  .controls {
    // Super jank IE7 fix to ensure the inputs in .input-append and input-prepend
    // don't inherit the margin of the parent, in this case .controls
    margin-left: $horizontalComponentOffset;
    &:first-child {
      *padding-left: $horizontalComponentOffset;
    }
  }
  // Remove bottom margin on block level help text since that's accounted for on .control-group
  .help-block {
    margin-bottom: 0;
  }
  // And apply it only to .help-block instances that follow a form control
  input,
  select,
  textarea {
    + .help-block {
      margin-top: calc($baseLineHeight / 2);
    }
  }
  // Move over buttons in .form-actions to align with .controls
  .form-actions {
    padding-left: $horizontalComponentOffset;
  }
}

//
// Forms (extension)
// --------------------------------------------------

input[type=text] {
  -webkit-appearance: none !important;
}


// FORM ACTIONS
// ------------

.form-actions {

  padding: $baseLineHeight;
  margin-top: $baseLineHeight;
  @include clearfix(); // Adding clearfix to allow for .pull-right button containers
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

.alwaysBottom {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.alwaysBottomContiner {
  position: relative;
  overflow-x: hidden;
}


// HELP TEXT
// ---------

.help-block,
.help-inline {
  color: lighten($textColor, 15%); // lighten the text some for contrast
}

.help-block {
  display: block; // account for any element using help-block
  margin-bottom: calc($baseLineHeight / 2);
}

.help-inline {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}


// INPUT GROUPS
// ------------

// Allow us to put symbols and text within the input field for a cleaner look
.input-append, .input-prepend {
  margin-bottom: 5px;
  font-size: 0;
  white-space: nowrap; // Prevent span and input from separating

  input, select, .uneditable-input {
    position: relative; // placed here by default so that on :focus we can place the input above the .add-on for full border and box-shadow goodness
    margin-bottom: 0; // prevent bottom margin from screwing up alignment in stacked forms
    font-size: $baseFontSize;
    vertical-align: top;
    @include border-radius(0 $inputBorderRadius $inputBorderRadius 0);
    // Make input on top when focused so blue border and shadow always show
    &:focus {
      z-index: 2;
    }
  }
  .add-on {
    display: inline-block;
    width: auto;
    height: $baseLineHeight + 6;
    min-width: 26px;
    padding: 2px 5px;
    font-size: $baseFontSize;
    font-weight: normal;
    line-height: $baseLineHeight;
    text-align: center;
    text-shadow: 0 1px 0 $white;
    background-color: $grayLighter;
    border: 1px solid #ccc;
  }
  .add-on, .btn {
    vertical-align: top;
    @include border-radius(0);
  }
  .active {
    background-color: lighten($green, 30);
    border-color: $green;
  }
}

//change the size of things that in the condensed version
.table-condensed {
  //@extend %appendPrepend;
  .add-on {
    height: ($baseLineHeight + 6);
    line-height: ($baseLineHeight - 4);
    min-width: 24px;
  }
}

.input-prepend {
  .add-on,
  .btn {
    margin-right: -1px;
  }
  .add-on:first-child,
  .btn:first-child {
    @include border-radius($inputBorderRadius 0 0 $inputBorderRadius);
  }
}
.input-append {
  input,
  select,
  .uneditable-input {
    @include border-radius($inputBorderRadius 0 0 $inputBorderRadius);
  }
  .add-on,
  .btn {
    margin-left: -1px;
  }
  .add-on:last-child,
  .btn:last-child {
    @include border-radius(0 $inputBorderRadius $inputBorderRadius 0);
  }
}
// Remove all border-radius for inputs with both prepend and append
.input-prepend.input-append {
  input,
  select,
  .uneditable-input {
    @include border-radius(0);
  }
  .add-on:first-child,
  .btn:first-child {
    margin-right: -1px;
    @include border-radius($inputBorderRadius 0 0 $inputBorderRadius);
  }
  .add-on:last-child,
  .btn:last-child {
    margin-left: -1px;
    @include border-radius(0 $inputBorderRadius $inputBorderRadius 0);
  }
}



// SEARCH FORM
// -----------

input.search-query {
  line-height: 1;
  padding-right: 7px;
  padding-right: 4px \9;
  padding-left: 7px;
  padding-left: 4px \9; /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0; // Remove the default margin on all inputs
  @include border-radius(1px);
}

/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  @include border-radius(0); // Override due to specificity
}
.form-search .input-append .search-query {
  @include border-radius(1px 0 0 1px);
}
.form-search .input-append .btn {
  @include border-radius(0 1px 1px 0);
}
.form-search .input-prepend .search-query {
  @include border-radius(0 1px 1px 0);
}
.form-search .input-prepend .btn {
  @include border-radius(1px 0 0 1px);
}


// only in the schedule area task pane is
// this label visible - see schedule.scss
.patientArea-search-label {
  display: none;
}

span.emptySearchFieldButton {
  position: absolute;
  display: none;
  background: url(daterangeclose.svg) center no-repeat;
  height: 26px;
  width: 21px;
  z-index: 9999999;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.inputWithEmptySearchFieldWrapper {
  position: relative;
  display: block;

  &.showCloseIcon {
    input {
      padding-right: 25px;
    }
    .emptySearchFieldButton {
      display: block;
    }
  }
}

.search-wrapper {
  button[type="submit"]{
    background: url(not_active_search_icon.png);
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0;
    text-indent: -999em;
    width: 25px;
    height: 16px;
    background-position: center;
    border-left: 1px solid #BFC7CC;
    position: absolute;
    top: 5px;
    right: 1px;

    &:disabled {
      cursor: default;
    }
  }

  span.emptySearchFieldButton {
    right: 25px;
  }

  &.showCloseIcon {
    input {
      padding-right: 43px !important;
    }
  }

  input[type="search"]:focus + button[type="submit"], input[type="text"]:focus + button[type="submit"]{
    background-image: url(search_icon.png);
  }

  input[type="search"], input[type="text"]{
    width: 100%;
    height: $baseLineHeight + 6;
    padding-right: 25px;
    box-sizing: border-box;
    margin-bottom: 0;
    &:focus{
      padding-right: 45px;
    }
  }

  //display: inline-block;
  position: relative;

}




// HORIZONTAL & VERTICAL FORMS
// ---------------------------

// Common properties
// -----------------

.form-search,
.form-inline,
.form-horizontal, .form-vertical {
  input,
  textarea,
  select,
  .help-inline,
  .uneditable-input,
  .input-prepend,
  .input-append {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;

    &:focus {
      border-color: $inputFocusBorder;
      outline: 0;
      outline: thin dotted \9; /* IE6-9 */
      @include box-shadow(#{0 0 8px $inputFocusBorder});
    }
  }
  // Re-hide hidden elements due to specifity
  .hide {
    display: none;
  }
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
// Remove margin for input-prepend/-append
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
// Inline checkbox/radio labels (remove padding on left)
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
// Remove float and margin, set to inline-block
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}


// Margin to space out fieldsets
.control-group {
  margin-bottom: calc($baseLineHeight / 2);
}

// Legend collapses margin, so next element is responsible for spacing
legend{width: 100%;} //needs to be set to 100% or it will be to short! Please don't change it back to 98% to get it work with your broken layout.
legend + .control-group {
  margin-top: $baseLineHeight;
  -webkit-margin-top-collapse: separate;
}

// Horizontal-specific styles
// --------------------------

.form-horizontal {
  &.form-condensed {
    .control-group {
      margin-bottom: $baseLineHeight * .5;
    }
  }
  // Increase spacing between groups
  .control-group {
    margin-bottom: $baseLineHeight;
    @include clearfix();
  }
  // Float the labels left
  .control-label {
    float: left;
    width: $horizontalComponentOffset - 10;
    padding-top: 3px;
    text-align: right;

    &.large {
      width: $horizontalComponentOffset * 1.5 !important;

      .controls {
        // Super jank IE7 fix to ensure the inputs in .input-append and input-prepend
        // don't inherit the margin of the parent, in this case .controls
        margin-left: $horizontalComponentOffset * 1.5 !important;
        &:first-child {
          *padding-left: $horizontalComponentOffset * 1.5 !important;
        }
      }
    }
  }
  // Move over all input controls and content
  .controls {
    // Super jank IE7 fix to ensure the inputs in .input-append and input-prepend
    // don't inherit the margin of the parent, in this case .controls
    margin-left: $horizontalComponentOffset;
    &:first-child {
      *padding-left: $horizontalComponentOffset;
    }

    .controls-paragraph {
      display: inline-block;
      padding-top: 5px;
      font-weight: bold;
    }

    .search-wrapper {
      &[class*="span"] {
        float: none;
        margin-left: 0;
      }
    }
  }
  // Remove bottom margin on block level help text since that's accounted for on .control-group
  .help-block {
    margin-bottom: 0;
  }
  // And apply it only to .help-block instances that follow a form control
  input,
  select,
  textarea {
    + .help-block {
      margin-top: calc($baseLineHeight / 2);
    }
  }
  // Move over buttons in .form-actions to align with .controls
  .form-actions {
    padding-left: $horizontalComponentOffset;
  }
}
// Vertical-specific styles
// --------------------------

.form-vertical {
  // Increase spacing between groups
  .control-group {
    margin-bottom: $verticalFormLineHeight;
    margin-left: $gridGutterWidth;
    @include clearfix();
  }
  // Float the labels left
  .control-label {
    padding-top: 3px;
    text-align: left;

    &.large {
      width: $horizontalComponentOffset * 1.5 !important;

      .controls {
        // Super jank IE7 fix to ensure the inputs in .input-append and input-prepend
        // don't inherit the margin of the parent, in this case .controls
        margin-left: 0 !important;
        &:first-child {
          *padding-left: $horizontalComponentOffset * 1.5 !important;
        }
      }
    }
  }
  // Move over all input controls and content
  .controls {
    &:first-child {
      *padding-left: $horizontalComponentOffset;
    }

    .controls-paragraph {
      display: inline-block;
      padding-top: 5px;
      font-weight: bold;
    }

    .search-wrapper {
      &[class*="span"] {
        float: none;
        margin-left: 0;
      }
    }
  }
  // Remove bottom margin on block level help text since that's accounted for on .control-group
  .help-block {
    margin-bottom: 0;
  }
  // And apply it only to .help-block instances that follow a form control
  input,
  select,
  textarea {
    + .help-block {
      margin-top: calc($baseLineHeight / 2);
    }
  }
  // Move over buttons in .form-actions to align with .controls
  .form-actions {
    padding-left: $horizontalComponentOffset;
  }
}

.controls-row {
  label {
    &[class*='span'] {
      padding-top: 3px;
      text-align: right;
    }

    &.alignLeft {
      text-align: left;
      padding-top:0;
    }
  }
}

.selectBoxShadow{
  width:240px;
  &:focus {
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    @include box-shadow(#{0 0 8px $inputFocusBorder});
  }
}

label {
  color:#666;
  &[disabled] {
    color: #999;
  }
}

.titleBar .title_button_area {
  input[type="checkbox"]:not(.bs_detach)+label {
    border: 1px solid rgba(255,255,255, .5);
  }

  input[type="checkbox"]:not(.bs_detach):not([disabled])+label:active, input[type="checkbox"]:not(.bs_detach):not([disabled]):checked+label, input[type="checkbox"]:not(.bs_detach):not([disabled])[indeterminate]+label {
    border: solid 1px #fff;
  }

}
.uneditable-input {
  color:#000;
  height: 21px;
  border-radius: 0;
  background-color: #f6f6f6;
}

.inputAsText {
  border-color: #fff !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.ui-widget {
  input[type="text"] {
    font-size: $baseFontSize;
  }
}
