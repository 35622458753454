@mixin triangle {
  position: absolute;
  top: -6px;
  display: inline-block;
  border-right: 16px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 16px solid transparent;
  content: '';
}

@mixin dropUpTriangle {
  position: absolute;
  bottom: -6px;
  display: inline-block;
  border-right: 16px solid transparent;
  border-top: 6px solid $white;
  border-left: 16px solid transparent;
  content: '';
}

@mixin triangleShadow {
  position: absolute;
  top: -6px;
  display: inline-block;
  border-right: 17px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 17px solid transparent;
  content: '';
}

@mixin invertedTriangle {
  position: absolute;
  bottom: -6px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

@mixin invertedTriangleShadow {
  position: absolute;
  bottom: -7px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.2);
  content: '';
}
@mixin dropUpTriangleShadow {
  position: absolute;
  bottom: -6px;
  display: inline-block;
  border-right: 17px solid transparent;
  border-top: 7px solid $white;
  border-left: 17px solid transparent;
  content: '';
}

.daterangepicker.dropdown-menu {
  max-width: none;
  border: 1px solid $dropdownBorder;
  @include box-shadow(0px 10px 30px 0px rgba($inputFocusBorder, 0.21));
  z-index: 999999;
  background: white;
  margin-top: 1px;

  &.drop-up {
    margin-top: -3px;
  }
}

.daterangepicker.opensleft .ranges, .daterangepicker.opensleft .BScalendar {
  float: left;
}

.daterangepicker.openstop .ranges, .daterangepicker.openstop .BScalendar,
.daterangepicker.opensright .ranges, .daterangepicker.opensright .BScalendar {
  float: right;
}

.daterangepicker.openstop .clendar_content .BScalendar.left,
.daterangepicker.opensleft .clendar_content .BScalendar.left,
.daterangepicker.opensright .clendar_content .BScalendar.left {
  margin: 0 10px 10px 10px;
  box-sizing: border-box;
  height: 182px;
}
.daterangepicker.openstop .clendar_content .BScalendar.right,
.daterangepicker.opensleft .clendar_content .BScalendar.right,
.daterangepicker.opensright .clendar_content .BScalendar.right {
  margin: 0 10px 0 0;
  box-sizing: border-box;
  height: 182px;
}

.daterangepicker.opensleft .specific ~ .ranges ul,
.daterangepicker.openstop .specific ~ .ranges ul,
.daterangepicker.opensright .specific ~ .ranges ul,
.daterangepicker.opensleft .range ~ .ranges ul,
.daterangepicker.openstop .range ~ .ranges ul,
.daterangepicker.opensright .range ~ .ranges ul {
  height: calc(100% - 30px);
  background-color: #f8f8f8;
  position: absolute;
  width: 161px;
}

.daterangepicker{
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 0;
  margin-top: 4px;


  .clendar_content{
    float: right;

    .single {
      margin: 10px !important ;
    }
  }

  &.opensleft:not(.drop-up) {

    &:before{
      right: 9px;
      @include triangleShadow;
    }

    &:after {
      right: 10px;
      @include triangle;
    }
  }

  &.opensleft.drop-up {

    &:before{
      right: 9px;
      @include dropUpTriangleShadow;
    }

    &:after {
      right: 10px;
      @include dropUpTriangle;
    }
  }

  &.opensright:not(.drop-up) {

    &:before{
      left: 9px;
      @include triangleShadow;
    }

    &:after {
      left: 10px;
      @include triangle;
    }
  }

  &.opensright.drop-up {

    &:before{
      left: 9px;
      @include invertedTriangleShadow;
    }

    &:after {
      left: 10px;
      @include invertedTriangle;
    }
  }


  .ranges {
    width: 162px;
    text-align: left;

    li.activeType {
      background-color: $selectSelected;
      font-size: 13px;
      font-weight: normal;
      color: #fff;
    }

    label {
      color: #333;
      margin-bottom: 2px;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      border-right: 1px solid rgb(222, 222, 222);

      li {
        background-color: #f8f8f8;
        color: $newBlueDark;
        //box-sizing: border-box;
        line-height: 9px;

        box-shadow: inset 0 1px $white;
        -webkit-box-shadow: inset 0 1px $white;
        -moz-box-shadow: inset 0 1px $white;

        padding: 12px 0px 12px 10px;
        cursor: pointer;
        border-top: 1px solid $inputDisabledBorderColor;
        border-left: none;

        font-size: 13px;
        font-weight: normal;

        &:last {
          border-bottom: none;
        }

        &.active, &:hover {
          background-color: $selectHover;
          box-shadow: none;
          color: #fff;
        }

        &:hover + li{
          border-top: 1px solid $btnPrimaryBackground;
        }
      }

    }

  }

  .BScalendar {
    background: #fff;
  }

  table.table-condensed {
    width: 100%;
    margin: 0;

    thead{
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color: #4d4d4d;
      .month.title {
        width: 70px ;
      }

    }

    .fixed {
      width: 100px;
    }

    &.calendarHeader{
      @include user-select(none);

      tr{
        height: 28px;

        th{
            vertical-align: middle;
            padding: 8px 2px 8px 2px;
            height: auto;
            line-height: 0;
         }
      }
    }

    &.calendarBody{

    }

    .subthead {
      background-color: #f8f8f8;
      -webkit-box-shadow: 0 1px rgba(206,206,206,.3);
      -moz-box-shadow: 0 1px rgba(206,206,206,.3);
      box-shadow: 0 1px rgba(206,206,206,.3);
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
      color: #707687;
      height: 22px;
    }

    .prev{
      width: auto;
      padding: 0 5px;
      text-align: right;
      i{
        position: relative;
        display: inline-block;
        border-top: 6px solid transparent;
        border-right: 7px solid #ccc;
        border-bottom: 6px solid transparent;
      }
    }
    .next{
      width: auto;
      padding: 0px 5px;
      text-align: left;
      i{
        position: relative;
        left: 0px;
        display: inline-block;
        border-top: 6px solid transparent;
        border-left: 7px solid #ccc;
        border-bottom: 6px solid transparent;
      }
    }

    .next.available i {
      border-left: 7px solid #406C86;
    }
    .prev.available i {
      border-right: 7px solid #406C86;
    }

    td, th {
      text-align: center;
      height: 20px;
      cursor: pointer;
      white-space: nowrap;
      padding: 0px;
      width: 30px;

      &.available {
        &:hover {
          background-color: $selectHover;
          color: #4d4d4d;
        }
        &.prev:hover {
          background: none;
          i{
            border-right: 7px solid rgb(65, 112, 140);
          }
        }
        &.next:hover{
          background: none;
          i{
            border-left: 7px solid rgb(65, 112, 140);
          }
        }
      }

    }
    td{
      font-size: 12px;
      font-weight: bold;
      color: #4d4d4d;
      border: 0;

      &.off {
        font-size: 12px;
        font-weight: 400;
        color: #c0c0c0;
      }

      &.disabled {
        color: #dedede;
        cursor: default;
      }

      &.active {
        background-color: $selectSelected;
        font-size: 12px;
        font-weight: bold;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
        border-color: #0044cc #0044cc #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
        color: #fff;

        &:hover {
          background-color: $selectSelected;
          font-size: 12px;
          font-weight: bold;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
          border-color: #0044cc #0044cc #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
          color: #fff;
        }
      }
    }

  }

  .header{
    font-size: 13px;
    color: #696c6e;
    position: relative;

    .headerRange{
      display: inline-block;
      min-width: 142px;
      padding: 5px 10px;
      white-space: pre;
      max-width: 162px;
    }

    .headerStart{
      display: none;
      width: 215px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: #696c6e;
    }

    .headerEnd {
      display: none;
      width: 230px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: #696c6e;
    }

    .subthead {
      background: #f5f5f5;
    }
  }

  &.onlyRanges {
    .header{
      .headerStart{
        display: inline-block;
        width: 215px;
        text-align: center;
        padding: 5px 0;
      }

      .headerEnd {
        display: inline-block;
        width: 230px;
        text-align: center;
        padding: 5px 0;
      }
    }

    .clendar_content {

      .form-actions {
        display: block;
      }
    }

  }

  &.sinCombobox {
    table {
      thead{
        tr{
          th{
            padding: 4px 5px;
          }
          .combo{
            padding: 0;
          }
          .month {
            padding: 4px 1px;
            input{
              width: 90px;
              height: 15px;
            }
            .ui-combobox .ui-combobox-input + .ui-corner-right{
              height: 18px;
            }

          }
          .year {
            padding: 4px 1px;
            input{
              width: 60px;
              height: 15px;
            }
            .ui-combobox .ui-combobox-input + .ui-corner-right{
              height: 18px;
            }
          }
        }

      }
    }
  }

  .specific{
    .headerStart{
      display: inline-block;
    }
    .headerEnd{
      display: none;
    }
  }

  .range{
    .headerStart{
      display: inline-block;
    }
    .headerEnd{
      display: inline-block;
    }
  }
  .form-actions {
    display: none;
    height: 45px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px 10px 10px;
    background-color: #f6f6f6;
    box-shadow: 0 -1px #cedae0;
  }
  .table td {
    line-height: 20px;
  }
}

.dateClose{
  display: inline-block;
  position: relative;

  input {
    @include box-sizing(border-box);
    height: 26px;
    min-width: 115px;
    padding-right: 20px;
  }
  &.showCloseIcon {
    .closeIconDatarangepicker {
      display: inline;
    }
  }
  .closeIconDatarangepicker {
    position: absolute;
    right: 5px;
    top: 0;
    display: none;
    height: 26px;
    width: 21px;
    background: url(daterangeclose.svg) no-repeat center;
    z-index: 3;
  }
}
