//
// Pagination (multiple pages)
// --------------------------------------------------

@import "variables_extended";

.paginationContainer {
  margin: $baseLineHeight 0;
  padding: 5px 8px;

  .pagination {
    color: $newBlueDark;
    margin-bottom: 0;
    margin-left: 0;

    .page-item, .page-link:hover,
    .active .page-link {
      color: $newBlueDark;
    }

    .page-item {
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      .page-link {
        background-color: transparent;
        text-align: center;
        color: $linkColor;
        padding: 6px 8px;
        margin-left: 0;
        line-height: 1;
        width: 31px;
        border: 0;

        &:hover {
          background-color: #dae7ef;
          color: $newBlueDark
        }

        &:focus {
          box-shadow: none;
        }

        .icon {
          height: 9px;
          width: 10px;
          background-image: url($iconSpritePathSVG)!important;
          display: inline-block;
        }
      }

      &.active {
        .page-link {
          background-color: $selectSelected;
          cursor: default;
          color: $white;
        }
      }
    }

    .page-item:last-child, .page-item:first-child {
      margin-right: 0;

      .page-link {
        width: auto !important;
      }
    }

    .page-item {
      &:first-child, &:last-child {
        &.disabled {
          a {
            color: $grayLight;
          }

        }
      }
    }

    .page-item:first-child {
      .icon {
        background-position: -102px -6px;
      }

      &.disabled {
        .icon {
          background-position: -54px -6px;
        }
      }
    }

    .page-item:last-child {
      .icon {
        background-position: -126px -6px;
      }

      &.disabled {
        .icon {
          background-position: -78px -6px;
        }
      }
    }
  }
}
