.iScrollBar{
  pointer-events: auto;
  // background-color: #315E79;
  // opacity: 0.5;
  background: rgba($selectSelected, .75);
  cursor: pointer;
}

.form-actions.iScrollIndicator {
  //background: #dddddd;
  //  @include linearGradient($white, $white, rgba(255, 255, 255, .15), 0%, 100%);
  //  @include linearGradient($white, $white, #EBEBEB, 0%, 100%);
  //border-top-color: $white;
  //-webkit-box-shadow: 0px -1px 300px 0px rgba(0, 0, 0, .25);
  box-shadow: 0px -1px 50px 25px rgba(255, 255, 255, 1.0);
  z-index: 3;
  position: absolute;
  bottom: 0;
}

#iScrollOuter.iScrollIndicator {
  //background: #dddddd;
  //  @include linearGradient($white, $white, rgba(0, 0, 0, .15), 90%, 100%);
  //border-top-color: $white;
  //-webkit-box-shadow: inset 0px -1px 300px 0px rgba(0, 0, 0, .25);
  //box-shadow: inset 0px -5px 10px 0px rgba(0, 0, 0, .15);
  z-index: 2;
  position: absolute;
}

.iScrollIndicator {
    //@include linearGradient($white, $white, #ddd, 20%, 100%);
}

.iScrollBothScrollbars {
  position:absolute;
  z-index:1001;

  .iScrollIndicator {
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    position:absolute;
    //background:rgba(0,0,0,0.5);   
	background: rgba($selectSelected, .75);
    border:1px solid rgba(255,255,255,0.9);
    border-radius:3px;
  }
}

.iScrollVerticalScrollbar {
  width: 10px;
  bottom:2px;
  top:2px;
  right:1px;

  .iScrollIndicator {
    width:  100%;
  }
}

.iScrollHorizontalScrollbar {
  height: 10px;
  left:2px;
  right:2px;
  bottom:0;

  .iScrollIndicator {
    height: 100%;
  }

}