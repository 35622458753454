.introjs-overlay {
  opacity: 0.35 !important;
  background-image: none;
  background-color: #000;
}

.introjs-fixParent {
  position: relative !important;
}

.introjs-tooltip.ascend-introjs-tooltip {
  border-radius: 2px;
  padding-top: 6px;
  max-width: none;

  .introjs-title {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom: 1px solid #7DABC8;
    @include inline-block(none);
    background-color: #D8EDF6;
    width: calc(100% + 18px);
    margin: -6px -9px 0;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
    color: #3385B2;
    padding: 6px 10px;
  }

  .introjs-arrow {
    zoom: 2;
  }

  .introjs-content {
    padding-top: 10px;
  }

  .introjs-bullets {
    height: 38px;

    ul {
      padding: 15px 0;
      margin: 0 auto;

      li {
        a {
          background-color: #BABEC8;
          border-radius: 50%;
          height: 8px;
          width: 8px;

          &.active {
            background-color: $newBlueDark;
          }
        }
      }
    }
  }

  .introjs-tooltipbuttons {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top: 1px solid #CEDAE0;
    @include inline-block(none);
    background-color: #F6F6F6;
    width: calc(100% + 18px);
    margin: 0 -9px -15px;
    padding: 9px;

    .introjs-button {
      background-image: linear-gradient(to bottom, #f6f6f6 28%, #d9d9d9);
      @include box-shadow(#{0 1px rgba(0,0,0,.05), inset 0 1px #fff});
      @include text-shadow(0 1px #fff);
      @include inline-block(middle);
      border: 1px solid $btnBorder;
      padding: 4px 15px 2px;
      border-color: #babec8;
      border-radius: 1px;
      margin: 0 10px 0 0;
      text-align: center;
      line-height: 18px;
      font-weight: 400;
      font-size: 13px;
      cursor: pointer;
      color: $newBlueDark;

      &:hover, &.hovered {
        background-image: linear-gradient(to bottom, #f2f2f2 24%, #cfcfcf);
        @include box-shadow(#{0 1px rgba(0,0,0,.1), inset 0 1px #fff});
        text-decoration: none;
        color: $newBlueDark;
      }

      &.active, &.selected, &:active {
        outline: 0;
        @include box-shadow(#{0 1px rgba(255,255,255,.7), inset 0 1px 3px rgba(0,0,0,.25)});
        background-image: linear-gradient(to top, #f6f6f6 45%, #d0d0d0);
        border: solid 1px #b1b5bd;
        color: $newBlueDark;
      }

      &.introjs-disabled, &[disabled] {
        background-image: linear-gradient(to bottom, #f6f6f6 45%, #f4f4f4);
        @include box-shadow(none);
        border: solid 1px #d4d4d4;
        cursor: default;
        color: #a0a0a0;
      }

      .back, .next {
        background-image: url($iconSpritePathSVG);
        display: inline-block;
        height: 13px;
        width: 14px;
      }
      .back {
        background-position: -91px -55px;
        margin-right: 5px;
      }
      .next {
        background-position: -119px -55px;
        margin-left: 5px;
      }
    }

    .introjs-prevbutton, .introjs-nextbutton {
      float: left;
    }

    .introjs-nextbutton {
      @include box-shadow(#{0 1px rgba(0,0,0,.05), inset 0 1px #99b5c5});
      border: solid 1px $newBlueDark;
      background-image: linear-gradient(to top, #41708c 28%, #73a0bb 100%);
      color: #fff;
      @include text-shadow(0 1px #1f4a63);

      &:hover{
        @include box-shadow(#{0 1px rgba(0,0,0,.1), inset 0 1px rgba(255,255,255,.32)});
        border: solid 1px $newBlueDark;
        background-image: linear-gradient(to top, #3c6781 24%, #6892aa);
        color: #fff;
        @include text-shadow(0 1px #1f4a63);
      }

      &:active, &.active{
        @include box-shadow(#{0 1px rgba(255,255,255,.7), inset 0 1px 3px rgba(0,0,0,.25)});
        border: solid 1px $newBlueDark;
        background-image: linear-gradient(to bottom, #41708c 45%, #73a0bb 100%);
        color: #fff;
        @include text-shadow(0 1px #1f4a63);
      }

      &.introjs-disabled, &[disabled] {
        cursor: default;
        background-color: #719eb9;
        border: solid 1px #5f91ae;
        color: #fff;
        @include text-shadow(none);
        @include box-shadow(none);
        background-image: none;
      }
    }

    .introjs-skipbutton {
      margin-right: 0;
      float: right;

      .introjs-donebutton {
        @extend .introjs-nextbutton;
        margin: -4px -15px -3px;
        padding: 4px 15px 3px;
        border: 0;

        &:hover {
          border: 0;
        }

        &:active {
          border: 0;
        }
      }
    }
  }
}
