input[type="checkbox"]:not(.bs_detach) {
  position: absolute;
  left: -9999em;



  &+label{
    top: 3px;
    padding: 7px;
    border-radius: $inputBorderRadius;
    -webkit-border-radius: $inputBorderRadius;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-left: -18px;
    margin-right: 1px;
    cursor: pointer;
    border: 1px solid;
    border-color: $inputBorder;
    background: #fff;
    height: 0;
    width: 0;
  }

  &[indeterminate]:not(:checked){
    &+label:before{
      content: "";
      height: 8px;
      width: 8px;
      top: 3px;
      left: 3px;
      position: absolute;
      background-color: $button-font-color;
    }
    &[disabled]+label:before {
      background: #ccc none;
    }
  }

  &+label:hover{
    color: $newBlueDark;
  }

  &:not([disabled]){
    &+label:active,
    &:checked+label,
    &[indeterminate]+label {
      border: solid 1px $inputBorder;
    }
  }

  &:checked + label {
    color: $button-font-color;
  }

  &:checked + label:after {
    content: '';
    background: url($iconSpritePathSVG) -173px -87px;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:disabled + label{
    background: $inputDisabledBackground;
    box-shadow: none;
    cursor: default;
    border-color: rgba(204,204,204,.5);
    &::after{
      background-position: -195px -87px;
    }
  }
}

.form-inline{
    input[type="checkbox"] + label{
        margin-left: 0;
    }
}

/* RADIO */

input[type="radio"]:not(.bs_detach) {
  // This styles for right work tab-navigation for custom radio control
  position: absolute;
  left: -9999px;

  & + label {
    margin-left: -18px;
    -webkit-appearance: none;
    background-color: $inputBackground;
    border: 1px solid $inputBorder;
    padding: 6px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 6px;
    cursor: pointer;
    border-color: $inputBorder;
    height: 0;
    width: 0;
  }

  &+label:hover, + .hovered{
    background-position: 0 -15px;
    transition: background-position .1s linear;
  }

  &:checked + label:after {
    content: ' ';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    background: $button-font-color;
    text-shadow: 0;
    left: 3px;
  }

  &:focus + label {
    box-shadow: 0 0 2px 1px $selectHover !important;
  }

  &:checked + label {
      background-color: #e9ecee;
      color: #99a1a7;
  }
  &:not([disabled]){
    & + label:active, &:checked + label:active, + label.mouse_down, &:checked + label.mouse_down {
      background-color: darken($white, 10%);
      background-color: darken($white, 15%) \9;
    }
  }

  &:disabled + label{
    background: $inputDisabledBackground;
    box-shadow: none;
    cursor: default;
    border-color: #e7e7e7;
    &::after{
      background-color: #719eb9;
    }
  }
}

// Don't hide radio buttons for Pendo
#pendo-base input[type="radio"] {
  position: relative !important;
  left: initial !important;
}

#pendo-base label {
  top: initial;
  border: initial !important;
}