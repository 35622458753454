//
// Popovers
// --------------------------------------------------

label.hasPopover {
  display: inline-block;
}

.popover {

  .custom-gap-header{
    color: #c59853;
  }

  @include box-shadow(0px 15px 30px 0px rgba(72, 122, 151, 0.21));
  background-color: $popoverBackground;
  border: 1px solid $popoverBackground;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  @include border-radius(2px);
  z-index: $zindexPopover;
  width: max-content;
  padding: 1px;


  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -10px; }
  &.right   { margin-left: 10px; }
  &.bottom  { margin-top: 10px; }
  &.left    { margin-right: 10px; }

  .popover-header {
    background-color: $popoverTitleBackground;
    @include border-radius(2px 2px 0 0);
    border-bottom: none;
    line-height: 18px;
    padding: 8px 14px;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }

  &.error{
    .popover-header {
      background-color: #f3dede;
      color: #be4847;
    }
  }

  &.success{
    .popover-header {
      background-color: #ddf1da;
      color: #39884c;
    }
  }

  &.info{
    .popover-header {
      background-color: #d8edf6;
      color: #3385b2;
      font-weight: 500;
      font-size: 14px;
    }
  }


  &.warning{
    .popover-header {
      background-color: #fcf8e4;
      color: #c5a167;
    }
  }

  &.gray{
    .popover-header {
      background-color: #f6f6f6;
      color: #666;
    }
  }

}

.popover-body {
  padding: 9px 14px;

  p, ul, ol {
    margin-bottom: 0;
  }

  p {
    line-height: $baseLineHeight;
  }

  ul, ol {
    margin-left: 25px;
  }
}
