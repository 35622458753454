.ui-datepicker {
  border:1px solid $inputFocusBorder;
  @include box-shadow(0px 10px 30px 0px rgba($inputFocusBorder, 0.21));

    a.ui-state-default  {
        border-bottom: 0;
    }

    .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
        top:2px;
    }

}