.nav {
  margin-bottom: $baseLineHeight;
  list-style: none;
  margin-left: 0;
}

.nav > li > a {
  display: block;
}

.nav > li > a:hover {
  background-color: $grayLighter;
  text-decoration: none;
}

.nav > .pull-right {
  float: right;
}

.nav-tabs,
.nav-pills {
  @include clearfix();
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  line-height: 14px;
}

.nav-tabs {
  border-bottom: 1px solid $tabBorderColor;
  padding-left: 10px;
  padding-top: 10px;

  &.btn-group{
    background: transparent;
    border-bottom: 0;
    padding: 0;
  }
}

.nav-tabs > li {
  margin-bottom: -1px;
  margin-right: 10px;
  font-weight: bold;
}

.nav-tabs > li > a {
  border: 1px solid $tabBorderColor;
  @include border-radius(1px 1px 0 0);
  line-height: $baseLineHeight;
  background: $tabBorderColor;
  padding-bottom: 8px;
  color: $newBlueDark;
  padding-top: 8px;
  outline: 0;
}

.nav-tabs > li > a.active,
.nav-tabs > * > a.active:hover {
  background-color: $white;
  cursor: default;
  color: $gray;
}

.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: $linkColor;

  &:hover {
    color: $linkColor;
  }
}

.nav-pills > li > a.active,
.nav-pills > li > a.active:hover {
  background-color: $linkColor;
  color: $white;
}

.nav-stacked > li {
  width: 100%;
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0; // no need for the gap between nav items
  background: #fff;
}

.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  @include border-radius(0);
}

.nav-tabs.nav-stacked > li > a:hover {
  z-index: 2;
}

.tab-content {
  overflow: auto;
}

.nav > .disabled > a {
  color: $grayLight;
}

.nav > .disabled > a:hover {
  background-color: transparent;
  text-decoration: none;
  cursor: default;
}

// From _navs_extended

.nav-tabs>li {
  margin-right: 3px;
  font-weight: 400;
}

.nav-tabs>li>a {
  padding:.5em 1em;
}

.nav-tabs>li>a:hover {
  background: $selectHover;
}

.nav-tabs > li > a.active {
  border-bottom-color: $white;
  background-color: $white;
  cursor: default;
}

.addProcedure .nav-tabs>li {
  margin-bottom: -2px;
}