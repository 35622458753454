@mixin box {
  position: absolute;
  width: 100%;
  height: 15px;
  box-sizing: border-box;
}

.stepArrow {
  position: relative;
  height: 28px;
  margin-left: 0px !important;
  font-size: 13px;
  color: $newBlueDark;
  display: inline-block;
  width: auto;
  margin-top: 2px;
  padding-right: 6px;

  .stepArrow-beginning {
      @include box;
      border: 1px solid $button-border-color;
  }

  .stepArrow-beginning-top {
    transform: skew(30deg);
    border-bottom: none;
  }

  .stepArrow-beginning-bottom {
    top: 15px;
    transform: skew(-30deg);
    border-top: none;
  }

  .stepArrowValue {
    position: relative;
    width: 100%;
    white-space: nowrap;
    line-height: 8px;
    margin-left: 2px;
    padding-left: 6px;
    padding-right: 0;

    &:hover {
      cursor: pointer;
    }

    &.invalid {
      margin-right: -21.5px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.invalid:before {
      content: '';
      background: url(svg-icons.svg) 355px 18px;
      display: inline-block;
      margin: -3px 2px;
      top: -1px;
      position: relative;
      bottom: 4px;
      height: 14px;
      width: 14px;
    }
  }

  &.active {
    height: 30px;
    .stepArrow-beginning {
      background: $newBlueDark;
      border: 1px solid $newBlueDark;
    }
    .stepArrow-beginning-top {
      border-bottom: none;
    }
    .stepArrow-beginning-bottom {
      border-top: none;
    }
    .stepArrowValue {
      color: #fffdfd;
    }

    &.completed {
      .stepArrow-beginning-bottom {
        border-bottom: 1px solid $newBlueDark;
      }
    }
  }
  &.completed {
    .stepArrow-beginning-bottom {
      border-bottom: 4px solid #85cd94;
    }
  }
  &.excluded:not(.active) {
    [class*=beginning-top] {
      background: #cccccc;
      box-shadow: inset 0 0 2px #cccccc;

      border-top: 1px solid #babec8;
      border-right: 1px solid #babec8;
      border-left: 1px solid #babec8;
    }
    [class*=beginning-bottom] {
      background: #cccccc;
      box-shadow: inset 0 0 2px #cccccc;

      border-bottom: 1px solid #babec8;
      border-right: 1px solid #babec8;
      border-left: 1px solid #babec8;
    }
    [class*=Value] {
      color: #666666;
      text-shadow: 0 1px #dedede;
    }
  }
}

.caption {
  padding-right: 10px !important;
}

.steps, .steps.nav-pills {
  list-style: none;
  margin-left: 0;

  li {
    a {
      background-color: transparent !important;
      color: $button-font-color;
      display: block;
    }

    a:hover {
      background-color: transparent !important;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

