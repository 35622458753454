.ui-accordion {
  border: 1px solid $list-border-color;
  .ui-accordion-header {
    font-weight: 500 !important;
    background: #fff;
    font-size: 15px;
    border-top: 1px solid $list-border-color;
    border-bottom: 1px solid $list-border-color;
    border-left: none;
    border-right: none;
    display: block;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    padding: 4px 10px 4px 18px !important;
    color: #43515b;
    margin: 0;       //<<<<<<<<<<<<<<<<<<<<<<<< !!!
    &.ui-state-active{
      border-bottom: 1px solid $list-border-color;
      background: #fff;
    }
    &:first-child{
      border-top: 0;
    }
    &:not(.ui-state-active){
      border-bottom: 0;
    }

    .ui-icon { top: 50%; margin-top: -8px; position: relative; top: 3px; }
    .ui-accordion-header-icon { position: absolute; left: .1em; top: 50%; margin-top: -8px; }
  }
  .ui-accordion-content {
    border: 0;
    overflow: auto;
    zoom: 1;
    width: 100%;
    background: rgb(242, 244, 247);
    .acc_list {
      list-style: none;
      margin: 0;
      font-weight: 500;
      font-size: 13px;
      li {
        display: block;
        border-bottom: 1px solid $list-border-color;
        padding: 4px 10px 4px 18px;
        cursor: pointer;
        color: $newBlueDark;
        font-weight: 500;
        &:last-child{
          border-bottom: 0;
        }
        &:first-child{
        }
        .pull-right-area {
          color: #8c8c8c;
        }
        .dispense {
          color: #7e7e7e;
        }
        &:hover {
          background: $selectHover;
        }
        &.highlight, &.highlight .pull-right, &.highlight .dispense{
          background-color: $selectSelected;
          color:white;
        }
      }
    }
  }
  .ui-accordion-icons { padding-left: 1.3em; }
  .ui-accordion-noicons { padding-left: .7em; }
  .ui-accordion-icons .ui-accordion-icons { padding-left: 2.2em; }
}

.ui-accordion .ui-icon{

  display: inline-block;
  position: relative;
  top: 2px;

}

.ui-accordion .ui-accordion-content .acc_list li .dispense {
    font-weight: 400;
    font-size: 12px;
}
