.expandable-container-wrapper {
  &:before {
    content: '';
    height: 1px;
    display: block;
  }

  .expandable-container {
    position: relative;
    background: #cfd7e1;
    box-shadow: inset 0px 5px 5px -5px rgba(0, 0, 0, 0.2), inset 0px -5px 5px -5px rgba(0, 0, 0, 0.2);
    padding: 10px 27px 0 10px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 9px;

    &:before {
      position: absolute;
      content: "";
      top: -7px;
      left: 48%;
      width: 15px;
      height: 15px;
      box-shadow: inset 4px 4px 4px -3px rgba(0,0,0,0.2);
      -webkit-transform: rotate(42deg) skew(-6deg);
      background: #cfd7e1;
    }
  }

  .expandable-close-button {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: #487A97;
    border-radius: 100%;

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      position: relative;
      left: 6px;
      top: 8px;
      background: transparent;
      border: 2px solid #fff;
      -webkit-transform: rotate(45deg);
      border-right: none;
      border-bottom: none;
    }
    &:hover {
      background: $selectHover;
      cursor: pointer;
    }
  }
}

@media (min-width: 1220px) {
  .expandable-container-wrapper {
    .expandable-container {
      padding-right: 34px;
    }
  }
}