.ui-autocomplete.ui-menu.ui-tagSearch {
  max-height: 299px;
  overflow: hidden;
  white-space: nowrap;
  padding: 1px 2px 1px 1px;

  .containerItem {
    border: none;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      overflow: hidden;
      max-height: 245px;

      &.itemsNumberExceed {
        overflow-x: hidden;
        overflow-y: auto;
      }

      &.forIPad {
        max-height: 137px;
      }

      >li {
        padding-top: 3px;
        padding-left: 1px;
        padding-right: 10px;
        height: 24px;
        &:first-child {
          padding-top: 2px;
        }
        &:last-child {
          padding-bottom: 3px;
        }
      }
    }
    li {
      label label {
        float: left;
      }
      div {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        text-indent: 12px;
        line-height: 23px
      }
    }
  }
  .controlsContainer {
    width: calc(100% + 1px);
    height: 25px;
    margin: 0 0 1px 0;
    font-size: 13px;
    color: #2f5d78;
    @include text-shadow(-1px 1px #fff);
    border-bottom: 1px solid #babec8;
    @include box-shadow(0 1px 0 #fff inset);
    background: #f6f6f6;
    .createNew {
      .icon-plus {
        width: 24px;
        height: 24px;
        vertical-align: top;
        margin-top: 0;
        margin-left: 1px;
        padding-right: 3px;
      }
      .newName {
        color: #000;
      }

      width: 100%;
      display: inline-block;
      line-height: 24px;
      text-indent: 1px;
      &:hover:not(.disabled) {
        background: #dedede;
        @include box-shadow(inset 0 -1px #fff);
        cursor: pointer;
      }
      &:active:not(.disabled) {
        background: #dedede;
        @include box-shadow(inset 0 -1px #ccc);
      }
      &.disabled {
        color: #999;
        background: #f6f6f6;
        @include box-shadow(inset 0 -1px #fff);
      }
    }
  }

  &[data-side='bottom'] .controlsContainer {
    margin: 1px 0 0 0;
    border-top: 1px solid #babec8;
    border-bottom: 1px solid #dddddd;
  }
}