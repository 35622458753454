.nav-stacked-tabs {
  padding-top: 0;
  padding-left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  .nav-stacked-item {
    margin-right: 0;
    .nav-stacked-item-text {
      display: inline-block;
      max-width: 100%;
      @include text-overflow();
    }
    .buttons {
      margin-top: -6px;
      .case-menu-rename {
        cursor: pointer;
        i {
          background: url(edit-btn.svg) no-repeat;
          @include iStyle;
        }
      }
      .case-menu-delete {
        cursor: pointer;
        i {
          background: url(close-btn.svg) no-repeat;
          @include iStyle;
        }
      }
    }
    &.active {
      border-bottom: 1px solid $list-border-color;
      color: $white;
      @include activeButtons;
      a {
        background-color: $selectSelected;
        color: $white;
      }
    }
    a {
      border-radius: 0 !important;
      border-color: $list-border-color;
      color: $newBlueDark;
      padding-right: 5px;
      .del, .edit {
        display: inline-block;
      }
    }
    &:not(.active) a:hover {
      border-bottom: 1px solid $list-border-color;
      background-color: $selectHover;
    }
  }
}