.scroll-shadow {
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 1000000;
    &.top-shadow {
        margin-top: -10px;
        @include box-shadow(0px 3px 3px 0px #cedae0);
    }
    &.bottom-shadow {
        margin-bottom: -10px;
        @include box-shadow(0px -2px 3px 0px #cedae0);
    }
    &.left-shadow {
        margin-left: -10px;
        @include box-shadow(3px 0px 3px 0px #cedae0);
    }
    &.right-shadow {
        margin-right: -10px;
        @include box-shadow(-2px 0px 3px 0px #cedae0);
    }
}