#busyIndicatorLetterMerge {
	display:block;
	z-index:10000;
	position:fixed;
	height: 110px;
	width:auto;
	top:30%;
	left:50%;
    background-color:#D9D9D9;
    box-shadow: none;
    border:none;
    margin-left:-50px;

}

.toothBg {
    background:  url(toothProgressIndicator.svg) no-repeat center center;
    width:100px;
    height: 100px;
    display:block;
    background-size: 100px 100px;
    float:left;
    position: absolute;
    z-index: 5;
}

.toothContainer {
    background-color: $newBlueDark;
    width:100px;
    height: 100px;
    display:block;
    float:left;
    position: absolute;
    z-index: 2;
    border-radius:4px;
}

.toothFill {
    background-color: white;
    width:50px;
    height: 0;
    display:block;
    float:left;
    position: absolute;
    z-index: 4;
    bottom:0;
}

.toothEmpty {
    background-color: #bbb;
    width:50px;
    height: 50px;
    display:block;
    float:left;
    position: absolute;
    z-index: 3;
    margin:28px 0 0 25px;
    overflow:hidden;
}

.toothCancel a:not([href]):not([tabindex]) {
    z-index: 5;
    display: block;
    position: absolute;
    clear: left;
    bottom:0;
    color:#75a3b4;
    text-decoration: none;
    margin-left:32px;
    margin-bottom:11px;
    font-size:11px;

    &:hover {
        text-decoration: underline;
        cursor:pointer;
        color:#aecad4;
    }
}

.toothLoading {
    z-index: 5;
    display: block;
    position: absolute;
    clear: left;
    top:0;
    color:#ddd;
    text-decoration: none;
    margin-left:26px;
    margin-top:5px;
    color:#fff;
    font-size:14px;
}

#percentLoadedMessage {
    margin: 6px auto;
    text-align: center;
    z-index: 6;
    position: relative;
}

#busyIndicator.spinner {
	background-color: $newBlueDark;
	display:none;
	z-index:10000;
	width:30px;
	height: 30px;
	padding: 0px;
    position:fixed;
    right:0;
	border: 1px solid rgba(255, 255, 255, .85);
    float:right;
	bottom:0;
	@include boxShadow(0, 0px, 5px, 1px, rgba(0,0,0,0.2));
    top:auto;
    left:auto;
    margin-left:auto;
    border-radius:0;

	#spinner {
		background: $newBlueDark url(spiffygif_60x60.gif) no-repeat 0px 0px;
		width:22px;
		height: 22px;
        background-size:22px 22px;
        margin:4px;
		border: 0 solid rgba(255, 255, 255, .55);
		@include border-radius(36px);
		position: absolute;
		z-index: 1;
        box-shadow:none;
	}

	#spinnerMessage {
		background-color: transparent;
        text-indent:30px;
		width: 22px;
		height: 22px;
		line-height: 21px;
		@include border-radius(25px);
		font-size: 13px;
		color: #000;
		text-shadow: none;
		filter: dropshadow(color=#fff, offx=0, offy=1);
		border: 0 solid rgba(150, 150, 150, .85);
		font-weight: normal;
		padding: 0;
		margin: 0px;
		text-align: center;
		position: absolute;
		z-index: 2;
        box-shadow:none;

		-webkit-animation-name: spinnerText;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: ease-out;
		-webkit-animation-duration: 2.65s;

	}
}

@-webkit-keyframes spinnerText {
	from {
		-webkit-transition:color 1s ease-in;
		color: #444;
	}
	to {
		-webkit-transition:color 1s ease-in;
		color: #ddd;
	}
}
