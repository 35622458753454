//
// Modals
// --------------------------------------------------

.modal-open .modal {
  .dropdown-menu {  z-index: $zindexDropdown + $zindexModal; }
  .dropdown.show { *z-index: $zindexDropdown + $zindexModal; }
  .popover       {  z-index: $zindexPopover  + $zindexModal; }
  .tooltip       {  z-index: $zindexTooltip  + $zindexModal; }
}

// Background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindexModalBackdrop;
  background-color: $black;
  // Fade for backdrop
  &.fade { opacity: 0; }
}

.modal-backdrop,
.modal-backdrop.fade.in {
  @include opacity(70);
}

// Base modal
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: $zindexModal;
  overflow: auto;
  width: 560px;
  margin: -250px 0 0 -280px;
  background-color: $white;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,.3);
  @include border-radius(1px);
  @include box-shadow(0 0px 0px 7px rgba(0, 0, 0, 0.5));
  @include background-clip(padding-box);
  &.fade {
    @include transition(#{'opacity .3s linear, top .3s ease-out'});
    top: -25%;
  }
  &.fade.in { top: 50%; }
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;

  .close { margin-top: 2px; }

  h3 {
    margin: 0;
    line-height: 30px;
  }
}

.modal-body {
  padding: 13px 10px 10px;
  min-height: 110px;
}

.modal-form {
  margin-bottom: 0;
}

.modal-footer.form-actions, .modal-footer {
  padding: 10px 10px 9px;
  margin-bottom: 0;
  border-top: 1px solid $formActionsBg;
  background-color: $formActionsBg;
  justify-content: normal;
  @include clearfix();
  align-items: normal;
  display: block;
  font-size: 0;

  .btn + .btn {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .btn-group .btn + .btn {
    margin-left: -1px;
  }

  .btn:not(:last-child) {
    margin-right: 0;
  }
}
