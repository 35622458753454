//
// Button groups
// --------------------------------------------------

.btn-group {
  white-space: nowrap;
  font-size: 0;

  .btn.active {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: default;
    color: $black;
  }

}

.btn-group + .btn-group {
  margin-left: 5px;
}

.btn-toolbar {
  font-size: 0; // Hack to remove whitespace that results from using inline-block
  margin-top: calc($baseLineHeight / 2);
  margin-bottom: calc($baseLineHeight / 2);

  .btn-group {
    display: inline-block;
  }
  .btn + .btn,
  .btn-group + .btn,
  .btn + .btn-group {
    margin-left: 5px;
  }
}

.btn-group > .btn {
  position: relative;
  @include border-radius(0);
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu {
  font-size: $baseFontSize; // redeclare as part 2 of font-size inline-block hack
}

// Reset fonts for other sizes
.btn-group > .btn-mini {
  font-size: 11px;
}
.btn-group > .btn-small {
  font-size: 12px;
}
.btn-group > .btn-large {
  font-size: 16px;
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  margin-left: 0;
  @include border-radius(1px 0 0 1px);
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  @include border-radius(0px 1px 1px 0px);
}
.btn-group > .btn:first-child:last-child{
  @include border-radius(1px);
}

// Reset corners for large buttons
.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 1px;
  -moz-border-radius-topleft: 1px;
  border-top-left-radius: 1px;
  -webkit-border-bottom-left-radius: 1px;
  -moz-border-radius-bottomleft: 1px;
  border-bottom-left-radius: 1px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 1px;
  -moz-border-radius-topright: 1px;
  border-top-right-radius: 1px;
  -webkit-border-bottom-right-radius: 1px;
  -moz-border-radius-bottomright: 1px;
  border-bottom-right-radius: 1px;
}

// On hover/focus/active, bring the proper btn to front
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.show .dropdown-toggle {
  outline: 0;
}

// Split button dropdowns
// ----------------------

// Give the line between buttons some depth
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  @include box-shadow(#{inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05)});
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}

.btn-group {
  .btn-primary {
    &:not(:disabled):not(.disabled):active {
      background-color: $button-primary-background-active;
    }
  }
}

.btn-group.show {
  .btn.dropdown-toggle {
    color: $newBlueDark;
  }
  .btn-primary.dropdown-toggle {
    background-color: $button-primary-background;
    border: solid 1px $newBlueDark;
    color: #fff;

    &:not(:disabled):not(.disabled):active {
      background-color: $button-primary-background-active;
    }
  }
  .btn-warning.dropdown-toggle {
    @include text-shadow(0 1px #af6f13);
    border: solid 1px #de700d;
    color: #fff;

  }
  .btn-danger.dropdown-toggle {
    border: solid 1px #aa100c;
    color: #fff;
  }
  .btn-success.dropdown-toggle {
    border: solid 1px #37904c;
    color: #fff;
  }
  .btn-info.dropdown-toggle {
    background-color: $btnInfoBackgroundHighlight;
  }
  .btn-inverse.dropdown-toggle {
    background-color: $btnInverseBackgroundHighlight;
  }
}

// Reposition the caret ??? - Check if needed
.btn .caret {
  margin-top: 6.5px;
  margin-left: 0;
}
// Carets in other button sizes
.btn-mini .caret,
.btn-small .caret,
.btn-large .caret {
  margin-top: 8px;
}
.btn-large .caret {
  margin-top: 10px;
  border-left-width:  5px;
  border-right-width: 5px;
  border-top-width:   5px;
}
// Upside down carets for .dropup
.dropup .btn-large .caret {
  border-bottom: 5px solid $black;
  border-top: 0;
}

// Account for other colors
.btn-primary,
.btn-warning,
.btn-danger,
.btn-info,
.btn-success,
.btn-inverse {
  .caret {
    border-top-color: $white;
    border-bottom-color: $white;
  }
}
