table {
  max-width: 100%;
  background-color: $tableBackground;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  margin-bottom: $baseLineHeight;
  margin-bottom: 0;
  width: 100%;

  thead {
    border: 1px solid $tableHeaderBorder;
    color: $tableHeaderTextColor;
    background: $tableHeaderBg;
    font-size: $baseFontSize;

    th {
      border: 1px solid $tableHeaderBorder;
      border-left: 1px solid transparent;

      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }

  // Cells
  th, td {
    @include text-overflow();
    vertical-align: top;
    line-height: 21px;
    padding: 6px 8px;
    text-align: left;
    color: $black;
  }

  th {
    color: $tableHeaderTextColor;
    font-weight: 500;
  }

  td {
    border: 0;
  }

  thead th {
    vertical-align: bottom;
  }

  tr, tr:hover{
    &.selected, &.active, &.checked, &.highlight {
      td {
        background-color: $selectSelected !important;
        border-top: $selectSelected;
        color: white !important;
      }
    }
    &.selected + td, &.active + td, &.checked + td, &.highlight + td {
      td {
        border-top: $selectSelected;
      }
    }
  }
  // Remove top border from thead by default
  thead:first-child tr:first-child th,
  thead:first-child tr:first-child td {
    border-top: 0;
  }

  tbody {
    border: 1px solid $tableBodyBorder;
  }
  // Account for multiple tbody instances
  tbody + tbody {
    border-top: 2px solid $tableHeaderBorder;
  }
}

.table-condensed {
  th,
  td {
    padding: 4px 5px;
  }
}

.table-bordered {
  border: 1px solid $tableBodyBorder;
  border-collapse: separate;

  td {
    border: 0;

    border-top: 1px solid $tableBodyBorder;
  }

  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top: 0;
  }

  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child,
  tfoot:last-child tr:last-child td:first-child {
    @include border-radius(0 0 0 1px);
    -webkit-border-bottom-left-radius: 1px;
    -moz-border-radius-bottomleft: 1px;
    border-bottom-left-radius: 1px;
  }
  thead:last-child tr:last-child th:last-child,
  tbody:last-child tr:last-child td:last-child,
  tfoot:last-child tr:last-child td:last-child {
    -webkit-border-bottom-right-radius: 1px;
    -moz-border-radius-bottomright: 1px;
    border-bottom-right-radius: 1px;
  }

  caption + thead tr:first-child th:first-child,
  caption + tbody tr:first-child td:first-child,
  colgroup + thead tr:first-child th:first-child,
  colgroup + tbody tr:first-child td:first-child {
    -webkit-border-top-left-radius: 1px;
    -moz-border-radius-topleft: 1px;
    border-top-left-radius: 1px;
  }
}

.table-striped {
  tbody {
    > tr:nth-child(odd) {
      background-color: transparent;
    }
    > tr:nth-child(even) td,
    > tr:nth-child(even) th {
      background-color: $tableBackgroundAccent;
    }

    > tr:nth-child(even) td.plan {
      @include text-overflow();
    }
  }
}

.table-hover {
  tbody {
    tr:hover td,
    tr:hover th{
      background-color: $tableBackgroundHover;
      border-color: $tableBackgroundHover;

      a {
        color: $tableHoverLinkColor;
      }

    }

    tr:hover + tr td{
      border-color: $tableBackgroundHover;
    }
  }
}

.table-click {
  > tbody {
    tr:hover td,
    tr:hover th{
      cursor: pointer;
      background-color: $tableBackgroundHover;
      border-color: $tableBackgroundHover;
    }

    tr:hover + tr td{
      border-color: $tableBackgroundHover;
    }
  }
}

.table tbody tr {
  &.success td {
    background-color: $successBackground;
  }
  &.error td {
    background-color: $errorBackground;
  }
  &.warning td {
    background-color: $warningBackground;
  }
  &.info td {
    background-color: $infoBackground;
  }
}

.table-hover tbody tr {
  &.success:hover td {
    background-color: darken($successBackground, 5%);
  }
  &.error:hover td {
    background-color: darken($errorBackground, 5%);
  }
  &.warning:hover td {
    background-color: darken($warningBackground, 5%);
  }
  &.info:hover td {
    background-color: darken($infoBackground, 5%);
  }
}

tbody.iScrollContent, tbody.scrollContent {
  > tr > td {
    padding: 0;
    white-space: normal !important;

    table tbody {
      border: none;
    }
  }
}

// NEEDS REFACTORING - look at this class and utils which use it!!!
.tableWithInnerScroll {
  width: calc(100% + 10px) !important;
  max-width: calc(100% + 10px) !important;
}