//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

// Core
.btn {
  border: 1px solid $button-border-color;
  background: $button-background;
  color: $button-font-color;
  -webkit-appearance: none;
  border-radius: 1px;
  line-height: 18px;
  padding: 3px 15px;
  text-shadow: none;
  transition: none;
  font-size: 13px;

  &[type="button"] {
    -webkit-appearance: none;
  }

  &:hover, &.hovered {
    border-color: $button-border-color-hover;
    background: $button-background-hover;
    color: $button-font-color-hover;
    text-decoration: none;
  }

  &.active, &.selected,
  &:active {
    border-color: $button-border-color-active;
    background: $button-background-active;
    color: $button-font-color-active;
    outline: 0;
  }

  &:focus, &:focus:active {
    outline: 0;
  }

  &.disabled,
  &[disabled] {
    border-color: $button-border-color-disabled;
    background: $button-background-disabled;
    color: $button-font-color-disabled;
    cursor: default;
    opacity: 1;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
  }
}
// *** TODO: use bs4 mixin button-variant() to do this!!!
.btn-primary {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $button-primary-background-active;
  }
}

.btn-danger {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background: $button-danger-background-active;
    border-color: $button-danger-border-color;
  }
}
// ***
.titleBar, .ui-dialog-content {
  .btn:focus {
    outline: none;
  }
}

// Button Sizes
// --------------------------------------------------

// Large
.btn-large {
  padding: 5px 14px 7px;
  font-size: $baseFontSize + 2px;
  line-height: normal;
}
.btn-large [class^="icon-"] {
  margin-top: 2px;
}

// Small
.btn-small {
  padding: 3px 9px;
  font-size: $baseFontSize - 2px;
  line-height: $baseLineHeight - 2px;
}
.btn-small [class^="icon-"] {
  margin-top: 0;
}

// Mini
.btn-mini {
  padding: 2px 6px;
  font-size: $baseFontSize - 3px;
  line-height: $baseLineHeight - 3px;
}

// Alternate buttons
// --------------------------------------------------

// Provide *some* extra contrast for those who can get it
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255,255,255,.75) !important;
}

// ATTENTION - replace with BS4 clas
.input-append {
  .btn {
    border-color: $inputBorder;
  }
}
.btn-primary {
  border: solid 1px $button-primary-border-color;
  background: $button-primary-background;
  color: $button-primary-font-color;

  &:hover{
    border: solid 1px $button-primary-border-color-hover;
    background: $button-primary-background-hover;
    color: $button-primary-font-color-hover ;
  }

  &:active, &.active{
    border: solid 1px $button-primary-border-color-active;
    background: $button-primary-background-active;
    color: $button-primary-font-color-active ;
  }

  &.disabled,
  &[disabled] {
    border: solid 1px $button-primary-border-color-disabled;
    background-color: $button-primary-background-disabled;
    color: $button-primary-font-color-disabled;
    cursor: default;
  }
}

.btn-warning {
  border: solid 1px $button-warning-border-color;
  background: $button-warning-background;
  color: $button-warning-font-color;

  &:hover{
    border: solid 1px $button-warning-border-color-hover;
    background: $button-warning-background-hover;
    color: $button-warning-font-color-hover;
  }

  &:active, &.active{
    border: solid 1px $button-warning-border-color-active;
    background: $button-warning-background-active;
    color: $button-warning-font-color-active;
  }

  &.disabled,
  &[disabled] {
    border: solid 1px $button-warning-border-color-disabled;
    background-color: $button-warning-background-disabled;
    color: $button-warning-font-color-disabled;
    cursor: default;
  }
}

.btn-danger {
  border: solid 1px $button-danger-border-color;
  background: $button-danger-background;
  color: $button-danger-font-color;

  &:hover{
    border: solid 1px $button-danger-border-color-hover;
    background: $button-danger-background-hover;
    color: $button-danger-font-color-hover ;
  }

  &:active, &.active{
    border: solid 1px $button-danger-border-color-active;
    background: $button-danger-background-active;
    color: $button-danger-font-color-active ;
  }

  &.disabled,
  &[disabled] {
    border: solid 1px $button-danger-border-color-disabled;
    background-color: $button-danger-background-disabled;
    color: $button-danger-font-color-disabled;
    cursor: default;
  }
}

.btn-success {
  border: solid 1px $button-success-border-color;
  background: $button-success-background;
  color: $button-success-font-color;

  &:hover{
    border: solid 1px $button-success-border-color-hover;
    background: $button-success-background-hover;
    color: $button-success-font-color-hover;
  }

  &:active, &.active{
    border: solid 1px $button-success-border-color-active;
    background: $button-success-background-active;
    color: $button-success-font-color-active;
  }

  &.disabled,
  &[disabled] {
    border: solid 1px $button-success-border-color-disabled;
    background-color: $button-success-background-disabled;
    color: $button-success-font-color-disabled;
    cursor: default;
  }
}

.btn[type="button"] {
  &.disabled,
  &[disabled] {
    cursor: default;
  }
}

.btn-info {
  background: $btnInfoBackground;
}

.btn-inverse {
  background: $btnInverseBackground;
}

h3#preAuth.btn {
  width: 233px;
  position: absolute;
  margin: 25px 0 0 -1px;
  border: 1px solid #babec8;

  @media (min-width: 1220px) {
    width: 289px;
  }
}

.btn {
  &.delete {
    float: right;
  }
}
