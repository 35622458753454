//
// Accordion
// --------------------------------------------------

// Parent container
.accordion {
  margin-bottom: $baseLineHeight;
}
 
// Group == heading + body
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  @include border-radius(4px);
}

.accordion-heading {
  border-bottom: 0;
}

.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}

// General toggle styles
.accordion-toggle {
  cursor: pointer;
}

// Inner needs the styles because you can't animate properly with any styles on the element
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}

@mixin iStyle {
  display: inline-block;
  height: 25px;
  width: 30px;
}
@mixin activeButtons {
  .buttons {
    .case-menu-rename {
      i {
        background: url(edit-btn-active.svg) no-repeat;
      }
    }
    .case-menu-delete {
      i {
        background: url(close-btn-active.svg) no-repeat;
      }
    }
  }
}
