@mixin borderRadius($radius: 3px) {
	@include border_radius_custom(all, $radius);
}

@mixin border_radius_custom($orientation, $radius: 3px) {
	@if $orientation == left {
		@include border-top-left-radius($radius);
		@include border-bottom-left-radius($radius);
	} @else if $orientation == right {
		@include border-top-right-radius($radius);
		@include border-bottom-right-radius($radius);
	} @else if $orientation == top {
		@include border-top-left-radius($radius);
		@include border-top-right-radius($radius);
	} @else if $orientation == bottom {
		@include border-bottom-left-radius($radius);
		@include border-bottom-right-radius($radius);
	} @else if $orientation == all {
		@include border-bottom-left-radius($radius);
		@include border-bottom-right-radius($radius);
		@include border-top-left-radius($radius);
		@include border-top-right-radius($radius);
	}
}

@mixin linearGradient($bgColor, $fromColor, $toColor, $colorStart:0%, $colorStop: 100%) {
	background-color: $bgColor;  //* for non-css3 browsers*//

	background: -moz-linear-gradient(top,  $fromColor $colorStart, $toColor $colorStop);
	background: -webkit-gradient(linear, left top, left bottom, color-stop($colorStart,$fromColor), color-stop($colorStop,$toColor));
	background: -webkit-linear-gradient(top,  $fromColor $colorStart,$toColor $colorStop);
	background: -o-linear-gradient(top,  $fromColor $colorStart,$toColor $colorStop);
	background: -ms-linear-gradient(top,  $fromColor $colorStart,$toColor $colorStop);
	background: linear-gradient(to bottom,  $fromColor $colorStart,$toColor $colorStop);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($fromColor)}', endColorstr='#{ie-hex-str($toColor)}', GradientType=0); // IE9 and down
}

@mixin boxShadow($h: 0, $v: 9px, $blur: 15px, $spread: -2px, $color: rgba(0, 0, 0, 0.2)) {
	//box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: $h $v $blur $spread $color;
	-moz-box-shadow: $h $v $blur $spread $color;
	box-shadow: $h $v $blur $spread $color;
}

@mixin boxShadowInset($h: 0, $v: 9px, $blur: 15px, $spread: -2px, $color: rgba(0, 0, 0, 0.2)) {
//box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset $h $v $blur $spread $color;
	-moz-box-shadow: inset $h $v $blur $spread $color;
	box-shadow: inset $h $v $blur $spread $color;
}

@mixin boxShadowInsetImportant ($h, $v, $blur, $spread, $color) {
  -webkit-box-shadow: inset $h $v $blur $spread $color !important;
  -moz-box-shadow: inset $h $v $blur $spread $color !important;
  box-shadow: inset $h $v $blur $spread $color !important;
}

.paddedSpan {
    padding: 0 10px;

	&.top {
		padding-top: 10px;
	}

	&.bottom {
		padding-bottom: 10px;
	}

	&[rel="left"]{
		padding: 0 0 0 10px;
	}

	&[rel="right"]{
		padding: 0 10px 0 0;
	}
}

.dashboardPage {
  margin-top: 0;
  padding-top:10px;
  position: relative;
}

.widgets {
  padding-right: 5px;
}

@mixin totalInfoStyle() {
  .totalInfo {
    font-weight: bold;
    color: #666;

    span {
      padding-left: 10px;
      color: #000;
    }
    //float: left;

    > div {
      //line-height: 25px;
      //margin-left: 75px;
    }

    .totalInfoAmountNotApplied,
    .totalInfoAmountApplied,
    .selectedForRefund,
    .notSelectedForRefund {
      &.countError {
        color: $red;
        span {
          color: $red !important;
        }
      }
    }

    .totalInfoAmountNotAppliedValue,
    .totalInfoAmountAppliedValue,
    .selectedForRefundValue,
    .notSelectedForRefundValue {
        right: 15px;
    }
  }
}

@mixin font($size, $height, $weight:normal, $typeFace: $baseFontFamily){
	font: $weight $size $typeFace !important;
	line-height: $height;
}

@mixin maxMinWidth($width) {
  max-width: $width;
  min-width: $width;
  width: $width;
}
