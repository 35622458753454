@import "variables_extended";

.dropdown-toggle:active,
.show .dropdown-toggle {
  outline: 0;
}

// The dropdown menu
// ------------------
.dropdown-menu {
  font-weight: 400; // we think the buttons look bad at 600 -ttoy
  position: absolute;
  z-index: $zindexDropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  padding: 0;
  margin: 0; // override default ul
  margin-top: 0;
  list-style: none;
  background-color: $dropdownBackground;
  border: 1px solid $dropdownBorder;
  @include border-radius(1px);
  @include box-shadow(0px 25px 30px -10px rgba(72, 122, 151, 0.21));
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;

  // Aligns the dropdown menu to right
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    @include nav-divider($dropdownDividerTop, $dropdownDividerBottom);
    border-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  // Links within the dropdown menu
  a {
    display: block;
    padding: 3px 20px;
    clear: both;
    line-height: $baseLineHeight;
    color: $dropdownLinkColor;
    white-space: nowrap;
  }
}

// Hover state
// -----------
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu.openSubMenu > a {
  background-color: $dropdownLinkBackgroundHover;
  color: $dropdownLinkColor;
  text-decoration: none;
}

// Active state
// ------------
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  text-decoration: none;
  outline: 0;
  background-color: $dropdownLinkBackgroundActive;
}

// Disabled state
// --------------
// Gray out text and ensure the hover state remains gray
.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: $grayLight;
}
// Nuke hover effects
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}

// Right aligned dropdowns
// ---------------------------
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// NEEDS REFACTORING - used in 2 templates only
// (clinicalNotesDropDownMenu.tpl, manageDropDownMenu.tpl)

.selectionDropDownMenu {
  .selectionDropDownMenuList {
    width: 100%;
    max-height: 261px;
    overflow-y: auto;
    margin: 0;
    a {
      text-decoration: none;
      color: #000000;
    }
  }
  .selectionDropDownMenuButtons {
    margin: 0;
    .selectionDropDownMenuButton {
      border-top: 1px solid #d4e1e9;
      list-style: none;
    }
    a {
      background-color: #f0f3fa;
      color: #487A97;
      border: 1px solid #ffffff;
      padding-left: 10px;
      &:hover {
        background-color: #fafcff;
      }

      .selectionDropDownMenuIcon {
        height: 24px;
        width: 24px;
        margin-right: 3px;
      }
    }
  }
}