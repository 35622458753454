/*!
 * Bootstrap v4.0.0 + ascend customized styles
 */

@import "bootstrap4";
//TODO: Here are all customized parts of original bs: buttons, forms, tables,...
@import "bootstrap4_extended";

//JQuery_UI theme
@import "libs/vendor/JQ_UI_1.12.1/jquery.ui.all";
@import "libs/ascend/JQ_UI_1.12.1_extension/jquery.ui.all_extended";
