// Base styles
// -------------------------

.alert {
  background-color: $warningBackground;
  margin-bottom: $baseLineHeight;
  padding: 8px 35px 8px 14px;
  @include border-radius(2px);
  color: $warningText;
  border: none;
}

.alert h4 {
  margin: 0;
}

// Adjust close link position
.alert .close {
  line-height: $baseLineHeight;
  position: relative;
  right: -21px;
  top: -2px;
}


// Alternate styles
// -------------------------

.alert.alert-inline {
  padding-right: 14px;
}
.alert-success {
  background-color: $successBackground;
  border-color: $successBorder;
  color: $successText;
}
.alert-danger,
.alert-error {
  background-color: $errorBackground;
  border-color: $errorBorder;
  color: $errorText;
}
.alert-info {
  background-color: $infoBackground;
  border-color: $infoBorder;
  color: $infoText;
}

.alert-information {
  background-color: $informationBackground;
  border-color: $informationBorder;
  color: $informationText;
}

// Block alerts
// -------------------------

.alert-block {
  padding-bottom: 10px;
  padding-top: 10px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

#notificationNote, .notificationNote {
  color: $grayLight;
  padding: 10px 0 0;
  font-size: 12px;
}

::-webkit-validation-bubble-message { display: none; }