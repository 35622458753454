$toggleColor:     $button-background;
$toggleColorBg:   $button-background;

.toggle-button {

  border-color: $button-border-color;
  
  display: inline-block;
  cursor: pointer;
  @include border-radius(2px);
  position: relative;
  text-align: left;
  overflow: hidden;
  -webkit-touch-callout: none;
  @include user-select(none);

  &.deactivate {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity = 50);
    opacity: 0.5;
    cursor: default !important;

    label, span {
      cursor: default !important;
    }

  }

  &>div {
    display: inline-block;
    width: 150px;
    position: absolute;
    top: 1px;
  }

  > div.disabled {
    left: -50%;
  }

  input[type=checkbox] {
    display: none;
  }

  span, label {
    cursor: pointer;
    position: relative;
    float: left;
    display: inline-block;
    right: 0;
  }

  label {
    background: $button-background;
    margin-left: -4px;
    margin-right: -4px;
    border: 1px solid $button-border-color;
    margin-top: -1px;
    z-index: 99;
    @include border-radius(2px);
    border-right: 1px solid $button-border-color;
    border-left: 1px solid $button-border-color;
  }

  span {
    color: #fefefe;
    text-align: center;
    font-weight: bold;
    z-index: 98;
  }

  span.labelLeft {
    @include border-radius(2px 0 0 2px);
    margin-right: -1px;
    padding-left: 4px;
    border: 1px solid;
    border-right: 0;
    border-color: $button-primary-border-color;
    text-indent: -7px;
    background: $button-primary-background;
    color: $button-primary-font-color;

    &:after{
      content: '';
      width: 1px;
      height: 25px;
      position: absolute;
      left: -1px;
      top: -1px;
    }

  }

  span.labelRight {

    border: 1px solid;
    border-top-color: $button-border-color;
    border-bottom-color: $button-border-color;
    margin-left: -2px;
    @include border-radius(0 2px 2px 0);
    color: $button-font-color;
    background: $button-background;
    padding-right: 3px;
    text-indent: 5px;

    &:after{
      content: '';
      width: 1px;
      height: 25px;
      position: absolute;
      right: -1px;
      top: -1px;
    }

  }

  .labelLeft, .labelRight, label {
    box-sizing: content-box;
  }
}